import { graphql } from 'gatsby';
import React from 'react';
import { Header, Item, ItemList, Layout, SEO } from '../components';
import PageProps from '../models/PageProps';
import { queryResultToPresentationList } from '../utils/contentList';

const IndexPage = ({ data }: PageProps) => {
  const posts = queryResultToPresentationList(data, []);
  return (
    <Layout>
      <SEO isPost={false} titlePrefix='Homepage | ' />
      <Header
        title='Telia Engineering & UX'
        icon='lightbulb'
        subHeading='Creative engineers and designers, building a world where you can belong anywhere'
      />

      <ItemList>
        {posts
          .filter(
            ({ date }) => {
              try {
                const blogDate = Date.parse(
                  date
                    .split('.')
                    .reverse()
                    .join('-')
                );
                return window.location.hostname !== 'engineering.telia.no' || blogDate < Date.now();
              } catch (e) {
                return true;
              }
            }
          )
          .slice(0, 9)
          .map((post, idx) => (
            <Item key={idx} {...post} />
          ))}
      </ItemList>
    </Layout>
  );
};

export default IndexPage;

export const IndexQuery = graphql`
  query {
    allMarkdownRemark(limit: 9, sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD.MM.YYYY")
            category
            image {
              publicURL
            }
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
    allPodcastRssFeedEpisode(limit: 9, sort: { fields: item___isoDate, order: DESC }) {
      nodes {
        item {
          title
          link
          content
          isoDate
          enclosure {
            length
            type
            url
          }
          guid
        }
      }
    }
    github {
      payload: search(query: "org:telia-oss", type: REPOSITORY, first: 1) {
        repositories: edges {
          repo: node {
            ... on GitHub_Repository {
              name
              id
              description
              stargazers {
                totalCount
              }
              forks {
                totalCount
              }
              lastUpdated: updatedAt
              url
            }
          }
        }
      }
    }
  }
`;
